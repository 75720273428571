import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logoImage from './routes/logo.png';
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { AuthContext } from './contexts/authContext'

interface HeaderProps {
    isAuthorized: boolean;
  }

const Header: React.FunctionComponent<HeaderProps> = ({ isAuthorized }) => {
    const history = useHistory()

    const auth = useContext(AuthContext)
  
    function signOutClicked() {
      auth.signOut()
      history.push('/')
    }

    const buttons = isAuthorized ? <>
    <Box m={2}  marginLeft="auto">
    <Button onClick={signOutClicked} variant="contained" color="primary">
        Sign Out
    </Button>
    </Box></> : null;

    return (<AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
          <img src={logoImage} width={350} alt="logo" />
          </Typography>
          <Typography variant="h6" color="inherit" noWrap>
            Job Offers Scrapper
          </Typography>
          {
            buttons
          }
          
        </Toolbar>
      </AppBar>
    )
}
            
export default Header;