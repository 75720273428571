import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./contexts/authContext";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from '@material-ui/core/Paper';
import csLogo from './routes/cs_logo.png';

import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  errorText: {
    color: theme.palette.error.main,
  }
}))

  const OAuthComponent = () => {
    const classes = useStyles()
    const auth = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const handleAuthorizationCode = async () => {
          setLoading(true);
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');
    
          if (code) {
            try{
              await auth.getToken(code);
            }catch{
              setError(true);
            }
          }
          urlParams.delete('code');
          window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams}`);
          setLoading(false);
        };

        handleAuthorizationCode();
      }, []);
    

    const client_id = process.env.REACT_APP_JOB_ADDER_CLIENT_ID;
    const redirect_uri = process.env.REACT_APP_JOB_ADDER_REDIRECT_URL;
    const jobAdderBaseUrl = process.env.REACT_APP_JOB_ADDER_BASE_URL;

    const authorizationUrl = `${jobAdderBaseUrl}?response_type=code&client_id=${client_id}&scope=write_job read&redirect_uri=${redirect_uri}`;
  
    const redirectToOAuth = () => {
      window.location.href = authorizationUrl;
    };
    
    return (
      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
      {loading === false ? <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="column" justify="center" alignItems="center">
            {/* Title */}
            <img src={csLogo} width={150} alt="logo" />
            <Box m={2}>
            <Typography variant="h3" align='center' style={{display: 'flex'}}>
          Sign In
          </Typography>
            </Box>
            <Box>
To proceed with signing in, you'll be redirected to the Job Adder authorization page. Click on 'Authorize' to continue.
            </Box>
            {error && <Typography variant="body1" className={classes.errorText}>
            Something went wrong. Please try sign in again
            </Typography>}
            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justify="center">
                <Box m={1}>
                  <Button color="primary" variant="contained" onClick={redirectToOAuth}>
                    Authorize
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper> : <CircularProgress />}
      </Grid>
    </Grid>
    );
  };
  
  export default OAuthComponent;