import React from 'react'

import TextField from '@material-ui/core/TextField'

export const Url: React.FunctionComponent<{ urlIsValid: boolean; setUrl: (_: string) => void }> = ({
  urlIsValid,
  setUrl,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={urlIsValid ? 'Url' : 'Invalid url'}
      error={!urlIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUrl(evt.target.value)
      }}
    />
  )
}

export const JobName: React.FunctionComponent<{ initValue?: string; jobNameIsValid: boolean; setJobName: (_: string) => void }> = ({
  jobNameIsValid,
  setJobName,
  initValue
}) => {
  return (
    <TextField
      value={initValue}
      fullWidth
      variant="outlined"
      label={jobNameIsValid ? 'Job name' : 'Invalid job name'}
      error={!jobNameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJobName(evt.target.value)
      }}
    />
  )
}
