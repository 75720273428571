import React, { useState } from 'react'

export interface IData {
  url?: string,
  setUrl?: any,
}

const defaultState: IData = {
  url: '',
  setUrl: () => {},
}

type Props = {
  children?: React.ReactNode
}

export const DataContext = React.createContext(defaultState)

const DataProvider = ({ children }: Props) => {
  const [url, setUrl] = useState()

  const state: IData = {
    url,
    setUrl,
  }

  return <DataContext.Provider value={state}>{children}</DataContext.Provider>
}

export default DataProvider
