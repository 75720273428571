import React from 'react'
import './App.css'

import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import Header from './Header';
import AskAI from './routes/process/askAI'
import DataProvider from './contexts/dataContext'
import Processing from './routes/process/processing'
import OAuthComponent from './OAuthComponent'

let lightTheme = createTheme({
  palette: {
    primary: {
      main: '#FFA000', // Change primary color to orange
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#DADADA', // Change background color
      },
    },
  },
})
lightTheme = responsiveFontSizes(lightTheme)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Header isAuthorized={false}/>
    <Switch>
      <Route path="/" component={OAuthComponent} exact/>
    </Switch>
  </Router>
)

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Header isAuthorized={true}/>
    <DataProvider>
    <Switch>
      <Route path="/processing" component={Processing} />
      <Route path="/" component={AskAI} />
    </Switch></DataProvider>
  </Router>
)

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  </ThemeProvider>
)

export default App
