import React, { useState, useEffect, useContext } from 'react'
import { getTokenFromJobAdder } from '../services/apiService';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  authStatus?: AuthStatus
  getToken?: any
  signOut?: any
}

const defaultState: IAuth = {
  authStatus: AuthStatus.Loading,
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.SignedOut)

  async function getToken(code: string) {
    try {
      const response = await getTokenFromJobAdder(code);
      window.localStorage.setItem('accessToken', response.access_token);
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }


  function signOut() {
    setAuthStatus(AuthStatus.SignedOut)
  }


  const state: IAuth = {
    authStatus,
    getToken,
    signOut,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
