import React, { useState, useContext, useEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import csLogo from '../cs_logo.png';
import { DataContext } from '../../contexts/dataContext';
import { addJob, scrapPage } from '../../services/apiService';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '80vh',
    padding: theme.spacing(2),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(4),
  },
  logWindow: {
    maxHeight: '50vh',
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  logEntry: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

const Processing: React.FunctionComponent<{}> = () => {
  const classes = useStyles();
  const logWindowRef = useRef<HTMLDivElement>(null); // Ref to the log window element

  const dataContext = useContext(DataContext);
  const [logs, setLogs] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const history = useHistory();

  useEffect(() => {
  const fetchData = async () => {
    try {
      setIsProcessing(true);
      setLogs((prevLogs) => [...prevLogs, 'Extracting job offer data from: ' + dataContext.url]);
      const responseData = await scrapPage({"url": dataContext.url});

      if (responseData.error !== undefined) {
        setLogs((prevLogs) => [
          ...prevLogs,
          `Warning: Couldn't extract page content, please try again later`
        ]);
        setIsProcessing(false);

        return;
      }
      
      setLogs((prevLogs) => [
        ...prevLogs,
        `Name: ${responseData.jobName}, Salary: ${responseData.salary}`
      ]);
      
      setLogs((prevLogs) => [
        ...prevLogs,
        `Work type: ${responseData.workType}, Company: ${responseData.companyName}`
      ]);

      setLogs((prevLogs) => [...prevLogs, 'Locations: ' + responseData.locations]);

      if(responseData.additionalLocations !== "N/A"){
        setLogs((prevLogs) => [
          ...prevLogs,
          `Warning: Page contains locations that will not be processed, check offer manually: ${responseData.additionalLocations}`
        ]); 
      }

      if (responseData.jobName == "N/A" || responseData.jobDescription == "N/A") {
        setLogs((prevLogs) => [...prevLogs, `Error: Couldn't extract required informations like job name, description or location.
          Please create a job manually or try to process again later`]);
        setIsProcessing(false);
        return;
      }
      
      await processLocations(responseData);
      setIsProcessing(false);
    } catch (error: any) {
        // Log error
        if (error.code == "ERR_BAD_REQUEST") {
          setLogs((prevLogs) => [...prevLogs, `Error: Please sign out and sign in again`]);
          setIsProcessing(false);
          return;
        }
        setLogs((prevLogs) => [...prevLogs, `Error: ${error.message}`]);
        setIsProcessing(false);
    }
  };

  fetchData();
}, []);

const processLocations = async (responseData: any) => {
  try {
    // Iterate over the locations array
    await Promise.all(responseData.locations.map(async (location: string) => {
      setLogs((prevLogs) => [...prevLogs, 'Creating job offer for location: ' + location]);
      var jobData = {
        "jobTitle": `${responseData.jobName}`,
        "jobDescription": `${responseData.jobDescription}`,
        "salary": `${responseData.salary}`,
        "location": `${location}`,
        "url": `${dataContext.url}`,
        "workType": `${responseData.workType}`,
        "companyName": `${responseData.companyName}`,
        "companyId": responseData.companyId,
        "contactId": responseData.contactId,
      }
      const response = await addJob(jobData)

      setLogs((prevLogs) => [...prevLogs, `https://us1.jobadder.com/joborders/${response.jobId}`]);
    }));

  } catch (error: any) {
    if (error.code == "ERR_BAD_REQUEST") {
      setLogs((prevLogs) => [...prevLogs, `Error: Please sign out and sign in again`]);
      setIsProcessing(false);
      return;
    }
    setLogs((prevLogs) => [...prevLogs, 'Error processing locations:' + error]);
    console.error('Error processing locations:', error);
  }
};

useEffect(() => {
  // Scroll log window to bottom when new logs are added
  if (logWindowRef.current) {
    logWindowRef.current.scrollTop = logWindowRef.current.scrollHeight;
  }
}, [logs]); // Scroll when logs change

return (
  <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={11} sm={8} md={6}>
        <Paper className={classes.paper}>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={csLogo} width={150} alt="logo" />
          <Typography variant="h3" align='center' style={{display: 'flex'}}>          
            Processing
          </Typography>
          <div className={classes.logWindow} ref={logWindowRef}>
            {logs.map((log, index) => (
              <div key={index} className={classes.logEntry}>
                <Typography variant="body1" className={log.startsWith("Error") || log.startsWith("Warning") ? classes.errorText : ''}>
                  {log.startsWith("http") ? <Link target="_blank" rel="noopener" href={log}>Created Job Link</Link> : log}
                </Typography>
              </div>
            ))}
            {isProcessing && <Grid container direction="row" justify="center"><CircularProgress/></Grid>}
          </div>
          {isProcessing && <Box m={1}>
            <Typography variant="body1" align='center' style={{display: 'flex'}}>
              Processing might take up to few minutes
            </Typography>
          </Box>}
          <Box mt={2}>
          <Grid container direction="column" justify="center">
            <Box m={1}>
            <Button color="primary" variant="contained" onClick={() => history.push('askAI')}>
              Close
            </Button>
            </Box>
            </Grid>
          </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
);
};

export default Processing;