import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import csLogo from '../cs_logo.png';
import { useValidUrl } from '../../hooks/useValidationHooks'
import { Url } from '../../components/sharedComponents'

import { DataContext } from '../../contexts/dataContext'

const useStyles = makeStyles({
  root: {
    height: '80vh',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
})

const AskAI: React.FunctionComponent<{}> = () => {
  const classes = useStyles()

  const { url, setUrl, urlIsValid } = useValidUrl('')
  const [error, setError] = useState('')

  const isValid = !urlIsValid || url.length === 0

  const history = useHistory()

  const dataContext = useContext(DataContext)

  const startProcessing = async () => {
    try {
      await dataContext.setUrl(url);
      history.push('/processing')
    } catch (err: any) {
      setError(err.message)
    }
  }

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="column" justify="center" alignItems="center">
            {/* Title */}
            <img src={csLogo} width={150} alt="logo" />
            <Box m={2}>            
            <Typography variant="h3" align='center' style={{display: 'flex'}}>
          Provide Url
          </Typography>
            </Box>
            
            {/* Sign In Form */}
            <Box width="80%" m={1}>
              <Url urlIsValid={urlIsValid} setUrl={setUrl} />{' '}
            </Box>

            {/* Error */}
            <Box mt={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2}>
              <Grid container direction="row" justify="center">
                <Box m={1}>
                  <Button disabled={isValid} color="primary" variant="contained" onClick={startProcessing}>
                    Ask AI
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AskAI
