
import axios from 'axios';
//Localhost
// const API_BASE_URL = 'https://localhost:7282';
// Dev
// const API_BASE_URL = 'https://bubgx54ia3rmg6mneinor5emta0mkiut.lambda-url.us-east-1.on.aws';
// Prod
const API_BASE_URL = 'https://7ubxhql6fyesv64y57nzanupey0nalxf.lambda-url.us-east-1.on.aws';

// Function to call the Scrapper endpoint
export const scrapPage = async (scrapData) => {
  try {
    const token = window.localStorage.getItem('accessToken');
    const response = await axios.post(`${API_BASE_URL}/Scrapper`, scrapData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error scrapping page:', error);
    throw error;
  }
}; 

export const getTokenFromJobAdder = async (code) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Scrapper/token?`, {
      params: {
        code: code,
        redirectUri: process.env.REACT_APP_JOB_ADDER_REDIRECT_URL
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error when getting token:', error);
    throw error;
  }
};

export const addJob = async (jobData) => {
  try {
    const token = window.localStorage.getItem('accessToken');
    const response = await axios.post(`${API_BASE_URL}/Scrapper/jobs`, jobData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error during job creation:', error);
    throw error;
  }
}; 